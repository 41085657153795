import request from '../util/request'
import qs from 'qs'

export default {
  authPageurl(data) {
    return request({
      url: '/xiaowang/auth/pageurl',
      method: 'post',
      data: qs.stringify(data)
    })
  }
}